<template>
  <div class="mt-2 pm-container card p-4">
    <div>
      <router-link class="btn btn-primary" to="/account">Zurück</router-link>
    </div>
    <hr>

    <div class="pm-section-title">
      <h4>Account</h4>
      <hr>
    </div>

    <div class="pm-section-content">
      <div class="row mb-3">
        <label for="inputEmail3" class="col-sm-2 col-form-label">Name und Vorname:</label>
        <div class="col-sm-10">
          <input v-model="account.name" type="email" class="form-control" id="inputEmail3" placeholder="Ruth Etti">
        </div>
      </div>

      <div class="row mb-3">
        <label for="inputEmail3" class="col-sm-2 col-form-label">Email:</label>
        <div class="col-sm-10">
          <input v-model="account.email" type="email" class="form-control" placeholder="ruthetti@web.de">
        </div>
      </div>

      <div class="row mb-1">
        <label for="inputEmail3" class="col-sm-2 col-form-label">Erstellt am:</label>
        <div class="col-sm-10 col-form-label">
          {{ $filters.datetime(account.created) }}
        </div>
      </div>

      <div class="row mb-1">
        <label for="inputEmail3" class="col-sm-2 col-form-label">Erste Anmeldung:</label>
        <div class="col-sm-10 col-form-label">
          {{ $filters.datetime(account.firstLogin) }}
        </div>
      </div>

      <div class="row mb-1">
        <label for="inputEmail3" class="col-sm-2 col-form-label">Letzte Anmeldung:</label>
        <div class="col-sm-10 col-form-label">
          {{ $filters.datetime(account.lastLogin) }}
        </div>
      </div>
    </div>

    <div class="pm-section-content">
      <div class="pm-section-title">
        <h4>Passwort</h4>
        <hr>
      </div>

      <div class="row mb-3">
        <label for="inputEmail3" class="col-sm-2 col-form-label">Password (optional):</label>
        <div class="col-sm-10">
          <input v-model="account.password" type="password" class="form-control" placeholder="12345">
        </div>
      </div>

      <div class="row mb-1">
        <label for="inputEmail3" class="col-sm-4 col-form-label">Fehlgeschlagene Anmeldeversuche:</label>
        <div class="col-sm-8 col-form-label">
          {{ account.failedLogins }}
        </div>
      </div>

      <div class="row mb-1">
        <label for="inputEmail3" class="col-sm-4 col-form-label">Letzte Passwordänderung:</label>
        <div class="col-sm-8 col-form-label">
          {{ $filters.datetime(account.lastPasswordChange) }}
        </div>
      </div>
    </div>


    <div class="pm-section-content">
      <div class="pm-section-title">
        <h4>Berechtigungen</h4>
        <hr>
      </div>

      <fieldset class="row mb-3">
        <legend class="col-form-label col-sm-2 pt-0"></legend>
        <div class="col-sm-10">
          <div class="form-check">
            <input v-model="account.isAdmin" class="form-check-input" type="checkbox" name="gridRadios" id="gridRadios3"
                   value="option1">
            <label class="form-check-label" for="gridRadios3">
              Ist Administrator
            </label>
          </div>
        </div>
      </fieldset>
    </div>


    <button @click="updateAccount" class="btn btn-success me-2">Speichern und zurück</button>


    <div class="mt-5">
      <div class="pm-section-title">
        <h4>Aktionen</h4>
        <hr>
      </div>
      <div class="card mb-3">
        <div class="card-header">
          Einladungsmail schicken
        </div>
        <div class="card-body">
          <p>Eine Einladungsmail schicken bedeutet folgendes:</p>
          <ul>
            <li>Dem Nutzer wird eine Mail gesendet mit einem Einladungslink</li>
            <li>Durch den Einladungslink kann der Nutzer ein Password und einen Namen für seinen Account vergeben und
              sich zukünftig auf dieser Plattform anmelden
            </li>
          </ul>

          <button @click="sendInvitationMail" class="btn btn-primary mt-1">Einladungsmail schicken</button>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          Account löschen
        </div>
        <div class="card-body">
          <p>Einen Account zu löschen bedeutet folgendes:</p>
          <ul>
            <li>Sämtliche Daten des Accounts werden gelöscht</li>
            <li>Der Account kann sich nicht mehr auf der Plattform anmelden</li>
          </ul>

          <button @click="deleteAccount" class="btn btn-danger">Account löschen</button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import AccountService from "../../service/account.service";

export default {
  name: "AccountEdit",

  mounted() {
    this.$store.dispatch("account/edit", {id: this.id}).then(data => {
      this.account = data;
    })
  },

  data() {
    return {
      id: this.$route.params.account,
      account: {}
    }
  },

  methods: {
    updateAccount() {
      this.$store.dispatch("account/update", this.account)
          .then(() => {
            alert("Gespeichert");
            this.$router.push("/account");
          })
          .catch(() => alert("Fehler beim Speichern."))
    },

    sendInvitationMail() {
      this.$store.dispatch("account/sendInvitationMail", this.account)
          .then(() => {
            alert("Gesendet");
          })
          .catch(() => alert("Fehler"));
    },

    deleteAccount() {
      AccountService.deleteAccount(this.id)
          .then(() => {
            alert("Gelöscht!");
            this.$router.push("/account");
          })
          .catch(() => alert("Fehler"));
    }
  }

}
</script>

<style lang="scss" scoped>

</style>