import {getAxios} from "../store/api";

class AccountService {
    deleteAccount(id) {
        return getAxios()
            .delete(`/account/${id}`)
            .then(response => {

                return response.data
            })
    }
}

export default new AccountService();